import { get, post } from '@/api/index';
export const column = params => get(`/api/paper/v1/column`, params);//栏目层级
export const special = params => get(`/api/paper/v1/special`, params);//栏目下的专区
export const catalogue = params => get(`/api/paper/v1/catalogue`, params);//考点专区
export const sjlist = params => get(`/api/paper/v1/list`, params);//试卷专区列表
export const updatecollect = params => post(`/api/paper/v1/updatecollect`, params);//添加收藏
export const delcollect = params => post(`/api/paper/v1/delcollect`, params);//删除收藏
export const home = params => get(`/api/paper/v1/home`, params);//首页统计
export const exam = params => get(`/api/paper/v1/exam`, params);//模拟考试专区列表
export const examsign = params => post(`/api/paper/v1/examsign`, params);//模拟报名
export const weakspotlist = params => get(`/api/paper/v1/weakspotlist`, params);//弱项提升列表
export const datareport = params => get(`/api/paper/v1/datareport`, params);//评估报告
export const answerlist = params => get(`/api/paper/v1/answerlist`, params);//练习历史
