<template>
  <div class="praticeHomekemu">
    <el-dialog
      :visible.sync="centerDialogVisible1"
      width="700px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="showClose"
    >
      <template slot="title">
        <div class="titleWrap">请选择希望进行练习的学科</div>
      </template>
      <div class="praticeHomeBrushQuestionsbody">
        <ul class="praticeHomeBrushQuestionsbodyoul">
          <li v-for="(item, index) in listData" :key="index">
            <div
              class="praticeHomeBrushQuestionsbodyoulfirst clearfix"
              @click="firstClick(item)"
              :class="{
                on: selectId == item.column_id,
                show: item.showFlag,
              }"
            >
              <i
                class="iconfont fl"
                :class="{
                  'icon-first': item.haschild == 1,
                }"
              ></i>
              <span class="fl">{{ item.column_name }}</span>
              <em class="iconfont icon-select" v-if="item.haschild == 0"></em>
            </div>
            <template v-if="item.haschild == 1">
              <div v-show="item.showFlag">
                <div
                  class="praticeHomeBrushQuestionsbodyoulsecond"
                  v-for="(ite, inde) in item.child"
                  :key="inde"
                >
                  <div
                    class="praticeHomeBrushQuestionsbodyoulsecondlist clearfix"
                    @click="secondClick(ite)"
                    :class="{
                      on: selectId == ite.column_id,
                      show: ite.showFlagChild,
                    }"
                  >
                    <i
                      class="iconfont fl"
                      :class="{ 'icon-second': ite.haschild == 1 }"
                    ></i>
                    <span class="fl">{{ ite.column_name }}</span>
                    <em
                      class="iconfont icon-select"
                      v-if="ite.haschild == 0"
                    ></em>
                  </div>
                  <template v-if="ite.haschild == 1">
                    <div v-show="ite.showFlagChild">
                      <div
                        class="praticeHomeBrushQuestionsbodyoulthird"
                        v-for="(it, ind) in ite.child"
                        :key="ind"
                      >
                        <div
                          class="
                            praticeHomeBrushQuestionsbodyoulthirdlist
                            clearfix
                          "
                          @click="thirdClick(it)"
                          :class="{
                            on: selectId == it.column_id,
                          }"
                        >
                          <i class="fl"></i>
                          <span class="fl">{{ it.column_name }}</span>
                          <em class="iconfont icon-select"></em>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </li>
        </ul>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="submit" style="font-size: 16px" :class="{ on: selectId != '' }"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { column } from "../assets/api";
export default {
  name: "praticeHomekemu",
  props: [],
  data() {
    return {
      centerDialogVisible1: false,
      listData: [],
      selectId: "",
      selectname: "",
      showClose: false,
    };
  },
  mounted() {
    this.column();
    this.selectname = sessionStorage.getItem("str_column");
    if (sessionStorage.getItem("column_id") != null) {
      this.showClose = true;
      this.selectId = sessionStorage.getItem("column_id");
    }
  },
  methods: {
    //获取栏目
    column() {
      column().then((res) => {
        if (res.code == 0) {
          this.listData = res.data;
          this.$parent.listshow = res.data
          if(this.listData.length>0&&!sessionStorage.getItem("str_column")){
            this.centerDialogVisible1 = true
          }
          this.listData.forEach((item) => {
            if (item.haschild == 1) {
              this.$set(item, "showFlag", false);
              item.child.forEach((ite) => {
                if (ite.haschild == 1) {
                  this.$set(ite, "showFlagChild", false);
                }
              });
            }
          });
        }
      });
    },
    firstClick(data) {
      if (data.showFlag != undefined) {
        data.showFlag = !data.showFlag;
      } else {
        this.selectId = data.column_id;
        this.selectname = data.str_column;
      }
    },
    secondClick(data) {
      if (data.showFlagChild != undefined) {
        data.showFlagChild = !data.showFlagChild;
      } else {
        this.selectId = data.column_id;
        this.selectname = data.str_column;
      }
    },
    thirdClick(data) {
      this.selectId = data.column_id;
      this.selectname = data.str_column;
    },
    submit() {
      if (this.selectId != "") {
        this.centerDialogVisible1 = false;
        this.$parent.crumbsname = this.selectname;
        sessionStorage.setItem("column_id", this.selectId);
        sessionStorage.setItem("str_column", this.selectname);
        location.reload();
      } else {
        this.$message({
          message: "请选择希望进行练习的学科",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/tiku";
</style>
